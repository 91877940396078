import moment from "moment";

let result = [];
export const currencyFormatter = (params) => {
  if (params.value) {
    const n =
      typeof params.value === "number"
        ? params.value
        : params.value.replace(/,|\$|€|¥/g, "");
    return "$" + formatNumber(n);
  }
  return null;
};
export const percentageFormatter = (params) => {
  if (params.value) {
    const n =
      typeof params.value === "number"
        ? params.value
        : params.value.replace(/,|\$|€|¥|%/g, "");
    return `${formatNumber(n, params.valueType)}%`;
  }
  return null;
};
export const NoFormatter = (params) => {
  if (params.value) {
    const n =
      typeof params.value == "number"
        ? params.value
        : params.value.replaceAll(",", "");
    return formatNumber(n);
  }
  return null;
};
export const formatNumber = (number, valueType) => {
  const expression =
    valueType === "float"
      ? typeof number === "string"
        ? parseFloat(number).toFixed(2)
        : number.toFixed(2)
      : Math.round(number).toString();
  return expression.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export const formatData = (type, params) => {
  switch (type) {
    case "currencyFormatter":
      return currencyFormatter(params);
    case "percentageFormatter":
      return percentageFormatter(params);
    case "numberFormatter":
      return NoFormatter(params);
    default:
      if (params.value) {
        if (params.data.symbol) {
          if (params.data.position === "start") {
            return `${params.data.symbol} ${params.value}`;
          }
          if (params.data.position === "end") {
            return `${params.value} ${params.data.symbol} `;
          }
        } else {
          return params.value;
        }
      } else {
        return null;
      }
      break;
  }
};

export const getColumnDefs = (
  columnData,
  isValueFormatterRequired,
  importStyle,
  customSort,
  dispatch,
  storedColumnDefs,
  clientConfig,
  tableInfo,
  tableTempData
) => {
  let [oldCur, newCur] = clientConfig?.[0]?.currency?.split(",") || ["", ""];
  columnData.forEach((obj, index) => {
    // value formatter

    if (obj["valueFormatterType"] || isValueFormatterRequired) {
      obj.valueFormatter = function (params) {
        if (params.node.group) {
          if (obj["cellRenderer"] === "CustomGroupCellRenderer") {
            const v = params.node.allLeafChildren[0].data[params.colDef.field];
            const res = getFormattedValue(obj["valueFormatterType"], v);
            return res;
          }
          return null;
        }
        if (!params.data) {
          return null;
        }
        return formatData(obj.valueFormatterType, {
          ...params,
          valueType: obj.valueType || null,
        });
      };
    }

    // obj.cellStyle = function (params) {
    //     console.log("PARAMS I",params,params.data,params.data && params.data.hasOwnProperty("is_colour"),(params.data && params.data.metric_level  === "OTB Cost $"))
    //     // OTB Cost $
    //    if (params.data && params.data.hasOwnProperty("is_color") && ( params.data.metric_level === "OTB Cost $" )) {
    //     return {backgroundColor: '#f07c7c'};
    //    }

    //   return null;
    // }

    if (!obj["cellStyle"] && !importStyle && !obj["noEditColor"]) {
      obj.cellStyle = function (params) {
        if (params.context && params.context.editedColumns) {
          const data = params.context.editedColumns.filter((obj) => {
            if (
              obj.rowIndex == params.rowIndex &&
              obj.colId == params.column.colId &&
              params.colDef.editColor
            ) {
              return obj;
            }
          });
          if (data.length > 0) {
            return {
              background: "#f6c6c8",
            };
          }
        }
        // review in season
        let regExp = /[a-zA-Z]/g;
        if (
          params.data &&
          params.data.hasOwnProperty("is_color") &&
          (params.data.metric_level === "OTB Cost $" ||
            params.data.metric_level === "OTB U") &&
          !regExp.test(params.value)
        ) {
          return { color: params.value.includes("-") ? "#F46F6F" : "#47CE57" };
        }
      };
    }
    if (obj["children"]) {
      getColumnDefs(
        obj["children"],
        isValueFormatterRequired,
        importStyle,
        customSort,
        dispatch,
        storedColumnDefs,
        clientConfig
      );
    }

    // if (obj["children"]) {
    //   obj["children"].filter((nestedObj) => {
    //     if (nestedObj["valueFormatterType"] || isValueFormatterRequired) {
    //       nestedObj.valueFormatter = function (params) {
    //         return formatData(nestedObj.valueFormatterType, params);
    //       };
    //     }
    //     if (!nestedObj["cellStyle"]) {
    //       nestedObj.cellStyle = function (params) {
    //         let regExp = /[a-zA-Z]/g;
    //         if (
    //           params.data &&
    //           params.data.hasOwnProperty("is_color") &&
    //           (params.data.metric_level === "OTB Cost $" ||
    //             params.data.metric_level === "OTB U") &&
    //           !regExp.test(params.value)
    //         ) {
    //           return {
    //             color: params.value.includes("-") ? "#F46F6F" : "#47CE57",
    //           };
    //         }
    //         if (params.context && params.context.editedColumns) {
    //           const nestedData = params.context.editedColumns.filter((obj) => {
    //             console.log(
    //               obj.rowIndex,
    //               params.rowIndex,
    //               obj.colId,
    //               params.column.colId
    //             );
    //             if (
    //               obj.rowIndex == params.rowIndex &&
    //               obj.colId == params.column.colId
    //             ) {
    //               return obj;
    //             }
    //           });
    //           if (nestedData.length > 0) {
    //             return {
    //               background: "#f6c6c8",
    //             };
    //           }
    //         }
    //       };
    //     }
    //   });
    // }
    if (obj?.["span"]) {
      obj.rowSpan = (params) => {
        return params?.data?.hasOwnProperty("#span")
          ? +params.data["#span"]
          : 1;
      };
      obj.cellStyle = (params) => {
        return {
          backgroundColor: "white",
          height: params?.data?.["#span"] > 1 ? "inherit" : "",
          ...obj?.style,
        };
      };
    }
    if (obj["editAction"]) {
      obj.valueSetter = (params) => {
        const isValueChanged = params.newValue !== params.oldValue;
        if (isValueChanged) {
          params.data[obj.field] = params.newValue;
          const dependents = {};
          obj.editAction.forEach((action) => {
            dependents[action.key] = action.value;
          });
          dispatch({
            type: "DEPENDENT_COMPONENTS",
            payload: dependents,
          });
        }
        return isValueChanged;
      };
    }
    if (obj["calculate"]) {
      const funcs = {
        "+": (a, b) => a + b,
        "-": (a, b) => a - b,
        "*": (a, b) => a * b,
        "/": (a, b) => a / b,
        "%": (a, b) => a * (b / 100.0),
        "<<": (a, b) => b || a,
      };
      const parseArg = (arg, node) => {
        if (typeof arg === "number") {
          return arg;
        } else {
          let v = 0;
          if (arg.charAt(0) === "#") {
            //pick agg data
            v = node?.parent?.aggData?.[arg.slice(1)] || 0;
          } else {
            v = node?.data?.[arg] || 0;
          }
          if (typeof v === "number") return v;
          v = v.replace(/,|%/g, "");
          return Number(v);
        }
      };
      const seq = obj.calculate;
      obj.valueGetter = (params) => {
        if (!params.node.group) {
          let result = parseArg(seq[0], params.node);
          for (let i = 2, j = 1; i < seq.length; i += 2, j += 2) {
            result = funcs[seq[j]](result, parseArg(seq[i], params.node));
          }
          const v = !isFinite(result)
            ? "0"
            : formatNumber(result, obj?.valueType);
          params.node.data[obj.field] = v;
          return v;
        }
        return "";
      };
    }
    if (obj["expression"]) {
      const ops = new Set(["+", "-", "*", "/"])
      obj.valueGetter = (params) => {
        const seq = obj.expression.map(v => {
          if (typeof v === "string" && !ops.has(v)) {
            let cellValue = params.node.data[v]
            if (typeof cellValue === "number") return cellValue;
            cellValue = cellValue.replace(/,|\$|%/g, "")
            return Number(cellValue)
          } return v;
        }).join('')
        const result = eval(seq);
        const v = !isFinite(result) ? "0" : formatNumber(result, obj?.valueType)
        params.node.data[obj.field] = v;
        return v;
      }
    }
    if (obj["calculate_v"]) {
      const funcs = {
        "+": (a, b) => a + b,
        "-": (a, b) => a - b,
        "*": (a, b) => a * b,
        "/": (a, b) => a / b,
        "%": (a, b) => a * (b / 100.0),
        "<<": (a, b) => b || a,
      };
      const parseArg = (arg, rows) => {
        if (typeof arg === "number") {
          return arg;
        } else {
          let v = rows.find((row) => row.id === arg)[obj.field];
          if (typeof v === "number") return v;
          v = v.replace(/,|%/g, "");
          return obj["valueType"] === "float" ? parseFloat(v) : parseInt(v);
        }
      };
      obj.valueGetter = (params) => {
        if (params?.data?.["#calculate"] !== "Y") {
          return params.data[obj.field];
        }
        const rows = [];
        params.api.forEachNode((node) => {
          rows.push(node.data);
        });
        const seq = params.colDef.calculate_v;
        let result = parseArg(seq[0], rows);
        for (let i = 2, j = 1; i < seq.length; i += 2, j += 2) {
          result = funcs[seq[j]](result, parseArg(seq[i], rows));
        }
        const v = !isFinite(result) ? "0" : formatNumber(result);
        params.data[obj.field] = v;
        return v;
      };
    }
    if (obj["addWeeksInDate"]) {
      obj.valueGetter = (params) => {
        let dateColumn = obj["addWeeksInDate"][0];
        let weekNoColumn = obj["addWeeksInDate"][1];
        const { data } = params;
        let dateStr = data?.[dateColumn];
        let weekNos = data?.[weekNoColumn];
        let noOfDays = weekNos * 7;
        let new_date = moment(dateStr, "MM-DD-YYYY")
          .add(noOfDays, "days")
          .format("MM-DD-YYYY");
        return new_date;
      };
    }
    if (obj["calc"]) {
      const funcs = {
        perc: (a, b) => {
          return Math.round(a * b * 0.01);
        },
        sub: (a, b) => {
          return a - b;
        },
      };
      obj.valueGetter = (params) => {
        const { args, func } = params.colDef.calc;
        const _args = args.map((field) => {
          const value = params.getValue(field);
          return parseInt(value.replace(",", ""));
        });
        const result = funcs[func](..._args);
        return isNaN(result) ? "-" : result.toString();
      };
    }
    if (obj["colorCode"] && obj["rowValue"]) {
      obj.cellStyle = (params) => {
        if (
          params.value &&
          obj["rowValue"].indexOf(params.data[`${obj.rowMappingKey}`]) !== -1
        ) {
          // const {
          //   value,
          //   colDef: { colorCode },
          // } = params;
          // let found = null;
          // if (colorCode.type === "range") {
          //   const _value = value.includes('%') ? parseInt(value.replace(/,|%/g, "")) : (isNaN(value) ? value :  parseInt(value) ) ;
          //   found = colorCode.cases.find(
          //     ({ min, max }) => _value >= min && _value < max
          //   );
          // } else {
          //   found = colorCode.cases.find((obj) => obj.value === value);
          // }
          // return {color: 'red', backgroundColor: 'green'};
          // return { background: found ? found.color : colorCode?.default };
          return null;
        }
        return null;
      };
    } else if (obj["colorCode"]) {
      obj.cellStyle = (params) => {
        const {
          value,
          colDef: { colorCode },
        } = params;
        let found = null;
        if (colorCode.type === "range") {
          if (value) {
            const _value = parseInt(value.replace(/,|%/g, ""));
            found = colorCode.cases.find(
              ({ min, max }) => _value >= min && _value < max
            );
          }
        } else {
          found = colorCode.cases.find((obj) => obj.value === value);
        }
        return { background: found ? found.color : colorCode?.default };
      };
    } else if (obj["cellColorCode"]) {
      obj.cellStyle = (params) => {
        const {
          value,
          colDef: { cellColorCode },
        } = params;
        let found = null;
        if (cellColorCode.type === "range") {
          if (value) {
            let updatedValue = value;
            updatedValue = updatedValue?.replaceAll("%", "");
            updatedValue = updatedValue?.replaceAll(",", "");
            updatedValue = updatedValue?.replaceAll("$", "");
            const _value = updatedValue.split(".")
              ? parseFloat(updatedValue)
              : parseInt(updatedValue);
            found = cellColorCode.cases.find(
              ({ min, max }) => _value >= min && _value <= max
            );
          }
        } else if (cellColorCode.type === "basedOnText") {
          if (value) {
            found = cellColorCode?.valuesObj[`${value}`]
              ? { color: cellColorCode?.valuesObj[`${value}`] }
              : null;
          }
        } else {
          found = cellColorCode.cases.find((obj) => obj.value === value);
        }
        return { background: found ? found.color : cellColorCode?.default };
      };
    }
    if (obj?.["allowCheckboxSuppress"]) {
      obj.checkboxSelection = (params) => {
        if (params.node.group) {
          return false;
        }
        const rowData = params?.data;
        return rowData && rowData?.["#hideCheckbox"] !== "Y";
      };
    }
    if (obj["headerName"]) {
      obj["headerName"] = configureLabel(obj["headerName"], oldCur, newCur);
    }
    if (customSort) {
      obj.comparator = (valueA, valueB, nodeA, nodeB, isDescending) => {
        const isNumber = isFinite(valueA) && isFinite(valueB);
        if (isNumber) {
          return valueA - valueB;
        }
        if (valueA == valueB) return 0;
        return valueA > valueB ? 1 : -1;
      };
    }
    if (obj["proportionSplit"]) {
      obj.valueGetter = (params) => {
        const totalValColumn = obj?.proportionSplit?.total_val_col;
        const totalCoumnValue = params?.data?.[totalValColumn];
        const activeColumn = obj?.field;
        const activeCellValue = params.data?.[activeColumn]?.split("|");
        let proportion =
          activeCellValue?.[1] ||
          params?.data?.[obj?.proportionSplit?.refColumn];
        let result;
        if (obj?.proportionSplit?.useActualValue) {
          const val = params.api.getValue(
            `${obj?.proportionSplit?.total_val_col}`,
            params.node
          );
          result = (val * proportion) / 100;
        } else {
          result = (totalCoumnValue * proportion) / 100;
        }
        return result?.toFixed(0);
      };
    }
    if (obj["interTableProportionSplit"]) {
      const props = obj?.interTableProportionSplit;
      obj.valueGetter = (params) => {
        let parentTableData =
          tableInfo[props?.parentTableKey]?.[props?.requiredIndex || 0];
        let activeCellValue = parentTableData?.[props?.total_val_col];
        let proportion = params?.data?.[obj?.field];
        let result = (activeCellValue * proportion) / 100;
        return result?.toFixed(0);
      };
    }
    columnData[index] = appendColumnDef(obj, storedColumnDefs);
  });

  return columnData;
};

export const getHeaderNames = (data, callingFrom) => {
  if (!callingFrom) {
    result = [];
  }
  data.filter((obj, index) => {
    if (obj.hasOwnProperty("children")) {
      getHeaderNames(obj["children"], "Nested");
    } else {
      result.push(obj.field);
    }
  });
};

export const defaultCellStyle = (params, props) => {
  if (!props?.importStyle) return null;
  const pinned = params.colDef?.pinned ? true : false;
  const pinnedStyle = {
    background: "#f8f8f8",
  };
  const disabledStyle = {
    background: "#f2f2f2",
    pointerEvents: "none",
    opacity: 0.75,
    ...(props?.disabledCellStyle || {}),
  };
  if (params.node.group) {
    if (!props.importStyleToGroup)
      return (pinned && pinnedStyle) || null
  }
  const data = params.node.group
    ? params.node.allLeafChildren[0].data
    : params.data;
  if (!data) return (pinned && pinnedStyle) || null;
  const isDisabled =
    data["#disable"] === "Y" || data[`#disable_${params.colDef.field}`] === "Y";
  const style = {
    ...(isDisabled ? disabledStyle : {}),
    color: data[`#textColor_${params.colDef.field}`] || "inherit",
  };
  if (!isDisabled) {
    const bg =
      data[`#color_${params.colDef.field}`] ||
      data["#color"] ||
      (pinned && pinnedStyle.background) ||
      (params.colDef.editable && props.editableColor);
    if (bg) {
      style.background = bg;
    }
  }
  if (data["#boundary"] === "Y") {
    style.borderTop = "1px solid black";
  }
  return style;
};

export const getRowClass = (params, rowIndex) => {
  // 	console.log(params)
  //   if (rowIndex) {
  //     return "my-shaded-effect";
  //   }
};

export const getRowHeight = (rowHeight, value) => {
  switch (rowHeight) {
    case "default":
      return 40;
    case "medium":
      return 29;
    case "small":
      return 25;
    case "custom":
      return value;
    default:
      return 40; //Default ag grid rowHieght
  }
};
function appendColumnDef(currentDef, storedDefs) {
  if (storedDefs) {
    const field = currentDef.field;
    const columnDef = storedDefs[field];
    if (columnDef) return { ...currentDef, ...columnDef };
  }
  return currentDef;
}
export function configureLabel(headerName, oldValue, newValue) {
  if (oldValue && newValue) {
    if (typeof headerName !== "string") return headerName;
    return headerName.replace(oldValue, newValue);
  }
  return headerName;
}
export function getFormattedValue(type, value) {
  switch (type) {
    case "percentageFormatter":
      return percentageFormatter({ value });
    case "currencyFormatter":
      return currencyFormatter({ value });
    case "numberFormatter":
      return NoFormatter({ value });
    default:
      return value;
  }
}

export function getBoolean(str) {
  return str === "Y" ? true : false;
}
