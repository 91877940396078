import { cloneDeep, isArray } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { formatNumber } from "../componentsV2/Table/utils";
import { deleteData } from "../pages/Home/action";

export default function useAction() {
  const {
    gridRefs,
    tableInfo,
    dropdownSelectionData,
    selectedRowInfo,
    dependentInfo,
    formEditedValues,
    modalRefs,
    buffer,
    paramsRefs,
    tableDefs,
    componentInfo,
  } = useSelector((state) => state.home);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  function onAction(action, dynamicPayload, selectedValue) {
    let actionOccurred = true;
    switch (action?.actionName) {
      case "REQUEST_API_CALL":
        {
          if (selectedRowInfo[`list`] && selectedRowInfo[`list`].length > 0) {
            deleteData(action.apiUlr, {
              model_names: selectedRowInfo[`list`].map((obj) => obj.name),
            }).then((res) => {
              if (typeof res === "object") {
                dispatch({
                  type: "DISPLAY_MESSAGE",
                  payload: {
                    showMessage: true,
                    message: action?.message,
                    position: "bottom-left",
                    variant: "success",
                  },
                });
              }
            });
          }
        }
        break;
      case "UPDATE_TABLES_DATA":
        {
          const payload = {};
          action.dest.forEach((key, index) => {
            payload[key] = tableInfo[action.src[index]];
          });
          dispatch({
            type: "TABLE_DATA",
            payload: payload,
          });
        }
        break;
      case "UPDATE_TABLE_DATA":
        {
          const sourceData = tableInfo[action.source_table_key];
          let payload = {};
          payload[`${action.parent_table_key}`] = sourceData;
          dispatch({
            type: "TABLE_DATA",
            payload: payload,
          });
        }
        break;
      case "UPDATE_TABLE_DATA_WITH_NEW_KEY":
        {
          const sourceData = tableInfo[action.source_table_key];
          let payload = {};
          payload[`${action.parent_table_key}`] = sourceData;
          dispatch({
            type: "TABLE_DATA_WITH_KEY",
            payload: payload,
          });
        }
        break;
      case "ADD_ROWS_BY_TAG":
        {
          const src = tableInfo[action.src_table_key];
          const dest = tableInfo[action.dest_table_key];
          let rowsToAdd = null;
          const { tagInfo } = action;
          if (tagInfo?.tagSets) {
            const tagSets = {};
            Object.keys(tagInfo.tagSets).forEach(
              (key) =>
                (tagSets[key] = new Set(
                  tagInfo.srcFromBuffer
                    ? buffer[tagInfo.tagSets[key]]
                    : tagInfo.tagSets[key]
                ))
            );
            rowsToAdd = src?.filter((row) => {
              return tagInfo.columns.every((field) =>
                tagSets[field].has(row[field])
              );
            });
          } else {
            let tags = tagInfo?.tags;
            if (tagInfo?.filters) {
              tags = tagInfo.filters.map((filterId) => {
                const options = dropdownSelectionData[filterId];
                if (options && options.length > 0) return options[0].id;
                return null;
              });
            }
            rowsToAdd = src?.filter((row) => {
              return tagInfo.columns.every(
                (field, index) => row[field] === tags[index]
              );
            });
          }
          dispatch({
            type: "TABLE_DATA",
            payload: {
              [action.dest_table_key]: action.overrideData
                ? rowsToAdd
                : [...dest, ...rowsToAdd],
            },
          });
        }
        break;
      case "ADD_ROWS_BY_SELECTION": {
        const selectedRows = selectedRowInfo[action.source_table_key];
        if (selectedRows && selectedRows.length > 0) {
          dispatch({
            type: "TABLE_DATA",
            payload: { [action.dest_table_key]: [...selectedRows] },
          });
        }
        break;
      }
      case "APPEND_ROWS_BY_SELECTION": {
        const selectedRows = selectedRowInfo[action.source_table_key];
        if (selectedRows && selectedRows.length > 0) {
          const params = {};
          action.destTables.forEach((key) => {
            const table = tableInfo[key] || [];
            selectedRows.forEach(
              (row, index) => (row.id = table.length + 1 + index)
            );
            params[key] = [...table, ...selectedRows];
          });
          dispatch({
            type: "TABLE_DATA",
            payload: params,
          });
        }
        break;
      }
      case "DELETE_ROWS": {
        let selectedRows = null;
        if (dynamicPayload && action?.payloadType) {
          if (action.payloadType === "row") {
            selectedRows = [dynamicPayload];
          }
        } else {
          selectedRows = selectedRowInfo[action.parent_table_key];
        }
        if (selectedRows && selectedRows.length > 0) {
          const params = {};
          const selectedRowIds = new Set(selectedRows.map((row) => row.id));
          action.tables.forEach((key) => {
            const table = tableInfo[key] || [];
            params[key] = table.filter((row) => !selectedRowIds.has(row.id));
          });
          dispatch({
            type: "TABLE_DATA",
            payload: params,
          });
        }
        break;
      }
      case "DELETE_ROWS_BY_TAG":
        {
          const { tagInfo } = action;
          const tagSets = {};
          if (tagInfo?.tagSets) {
            tagInfo.columns.forEach(
              (field) => (tagSets[field] = new Set(tagInfo.tagSets[field]))
            );
          } else {
            tagInfo.columns.forEach((field) => (tagSets[field] = new Set()));
            const selectedRows = gridRefs[action.src_table_key].current.api
              .getSelectedNodes()
              ?.map((node) => node.data);
            if (selectedRows && selectedRows.length > 0) {
              selectedRows.forEach((row) =>
                tagInfo.columns.forEach((field) =>
                  tagSets[field].add(row[field])
                )
              );
            }
          }
          const rows = tableInfo[action.dest_table_key].filter((row) => {
            return !tagInfo.columns.every((field) =>
              tagSets[field].has(row[field])
            );
          });
          dispatch({
            type: "TABLE_DATA",
            payload: { [action.dest_table_key]: [...rows] },
          });
        }
        break;
      case "SET_ROWS_DATA": {
        const tableRows = tableInfo[action.parent_table_key];
        if (tableRows) {
          if (action?.limitToSelections) {
            if (action?.basedOnId) {
              let selectedRows = null;
              if (dynamicPayload) {
                selectedRows =
                  action.payloadType === "row"
                    ? [dynamicPayload.data["id"]]
                    : dynamicPayload;
              } else if (action?.source_table_key) {
                const rows = selectedRowInfo[action.source_table_key];
                if (rows && rows.length > 0) {
                  selectedRows = rows.map((row) => row["id"]);
                }
              } else {
                selectedRows = gridRefs[action.parent_table_key]?.current.api
                  .getSelectedNodes()
                  .map((node) => node.data["id"]);
              }
              if (selectedRows && selectedRows.length > 0) {
                const selectedIds = new Set(selectedRows);
                const rows = [...tableRows];
                rows.forEach((row) => {
                  const flag = action?.invertSelection
                    ? !selectedIds.has(row.id)
                    : selectedIds.has(row.id);
                  if (flag) {
                    action.data.forEach((obj) => {
                      const v = obj.hasOwnProperty("source")
                        ? row[obj.source]
                        : obj.value;
                      row[obj.field] = v;
                    });
                  }
                });
                dispatch({
                  type: "TABLE_DATA",
                  payload: { [action.parent_table_key]: [...rows] },
                });
              }
            } else {
              const selectedRows = gridRefs[
                action.parent_table_key
              ]?.current.api
                .getSelectedNodes()
                .map((node) => node.rowIndex);
              if (selectedRows && selectedRows.length > 0) {
                const rows = [...tableRows];
                selectedRows.forEach((rowIndex) => {
                  const row = rows[rowIndex];
                  action.data.forEach((obj) => (row[obj.field] = obj.value));
                });
                dispatch({
                  type: "TABLE_DATA",
                  payload: { [action.parent_table_key]: [...rows] },
                });
              }
            }
          } else {
            const rows = [...tableRows];
            rows.forEach((row) =>
              action.data.forEach((obj) => {
                if (obj?.bufferKey) {
                  row[obj.field] = buffer[obj.bufferKey];
                } else if (dynamicPayload) {
                  if (action.payloadType === "row") {
                    //dynamicPayload should be node in case of type row
                    row[obj.field] = dynamicPayload.data[obj.sourceField];
                  }
                } else {
                  row[obj.field] = obj.value;
                }
              })
            );
            dispatch({
              type: "TABLE_DATA",
              payload: { [action.parent_table_key]: rows },
            });
          }
        }
        break;
      }
      case "BULK_EDIT_DROPDOWNS": {
        const selectedRows = [...selectedRowInfo[`${action.parent_table_key}`]];
        if (selectedRows && dropdownSelectionData) {
          let output = [];
          if (action?.source_dropdowns && dropdownSelectionData) {
            const dropdownsValues = action.source_dropdowns.map((value) => {
              if (Array.isArray(value)) {
                return value
                  .map((id) => {
                    const selectedOptions = dropdownSelectionData[id];
                    return selectedOptions ? selectedOptions[0]?.value : null;
                  })
                  .join(",");
              } else {
                const selectedOptions = dropdownSelectionData[value];
                return selectedOptions ? selectedOptions[0]?.value : null;
              }
            });
            output = dropdownsValues;
          }
          if (action?.source_textFields && formEditedValues) {
            const textFieldsValues = action.source_textFields.map(
              (name) => formEditedValues[name]
            );
            output = output.concat(textFieldsValues);
          }
          const gridApi = gridRefs[action.parent_table_key].current.api;
          if (output && output.length > 0) {
            const selectedRowIndexes = gridApi
              .getSelectedNodes()
              .map(({ rowIndex }) => rowIndex);
            const allRows = [...tableInfo[action.parent_table_key]];
            selectedRowIndexes.forEach((index) => {
              const row = allRows[index];
              action.dest_fields.forEach((field, i) => {
                if (output[i]) row[field] = output[i];
              });
            });
            dispatch({
              type: "TABLE_DATA",
              payload: { [action.parent_table_key]: [...allRows] },
            });
          }
          if (!action?.preserveSelections) {
            gridApi.forEachNode((node) => node.setSelected(false));
          }
        }
        break;
      }
      case "click": {
        let params = {};

        params[`${action.key}`] = dependentInfo[`${action.key}`]
          ? !dependentInfo[`${action.key}`]
          : true;
        if (action.otherKey && dependentInfo[`${action.otherKey}`]) {
          params[`${action.otherKey}`] = dependentInfo[`${action.otherKey}`]
            ? !dependentInfo[`${action.otherKey}`]
            : true;
        }
        if (action.otherKey2 && dependentInfo[`${action.otherKey2}`]) {
          params[`${action.otherKey2}`] = dependentInfo[`${action.otherKey2}`]
            ? !dependentInfo[`${action.otherKey2}`]
            : true;
        }
        if (action.otherKey3 && dependentInfo[`${action.otherKey3}`]) {
          params[`${action.otherKey3}`] = dependentInfo[`${action.otherKey3}`]
            ? !dependentInfo[`${action.otherKey3}`]
            : true;
        }
        if (action.hasOwnProperty("defaultToggle")) {
          params[`${action.key}`] = action.defaultToggle;
        }
        dispatch({
          type: "DEPENDENT_COMPONENTS",
          payload: params,
        });
        break;
      }
      case "RESET_TABLE": {
        const gridRef = gridRefs[action.parent_table_key].current;
        gridRef.api.forEachNode((node) => node.setSelected(false));
        dispatch({
          type: "EDIT_ACTION",
          payload: { [action.parent_table_key]: false },
        });
        break;
      }
      case "SET_KEYS": {
        dispatch({
          type: "DEPENDENT_COMPONENTS",
          payload: action.data,
        });
        if (action.path) {
          navigate(
            "/" +
              window?.location?.pathname?.split("/")[1] +
              "/" +
              window?.location?.pathname?.split("/")[2] +
              action.path
          );
        }
        break;
      }
      case "COPY_COLUMNS_DATA": {
        //copy columns data from one table to other table (from selected rows or whole table)
        //Both tables needs 'id' column to make it work
        const selectedRows = action?.limitToSelections
          ? selectedRowInfo[action.source_table_key]
          : tableInfo[action.source_table_key];
        if (selectedRows && selectedRows.length > 0) {
          const idSet = {};
          selectedRows.forEach((row) => (idSet[row.id] = row));
          const rows = [...tableInfo[action.dest_table_key]];
          rows.forEach((row) => {
            if (idSet.hasOwnProperty(row.id)) {
              action.dest_fields.forEach(
                (field, index) =>
                  (row[field] = idSet[row.id][action.source_fields[index]])
              );
            }
          });
          dispatch({
            type: "TABLE_DATA",
            payload: { [action.dest_table_key]: rows },
          });
        }
        break;
      }
      case "CLOSE_PARENT_MODAL": {
        modalRefs[action?.parent_modal_key].current.close();
        break;
      }
      case "ADD_ROWS_TO_BUFFER": {
        if (selectedRowInfo[action.selectionSource]) {
          const selectionSet = new Set(
            selectedRowInfo[action.selectionSource].map((row) => row.id)
          );
          const rows = tableInfo[action.sourceTableKey].filter((row) =>
            selectionSet.has(row.id)
          );
          dispatch({
            type: "TABLE_DATA",
            payload: { [action.destTableKey]: [...rows] },
          });
        }
        break;
      }
      case "MULTIPLY_BY_CELLS": {
        const targetTable = [...tableInfo[action.targetTableKey]];
        const sourceTable = tableInfo[action.srcTableKey];
        const rowSet = new Set(action.targetRows);
        const srcData = {};
        sourceTable.forEach((row) => {
          if (rowSet.has(row.id)) srcData[row.id] = row;
        });
        targetTable.forEach((row) => {
          if (rowSet.has(row.id)) {
            action.targetFields.forEach((field) => {
              const srcValue = parseFloat(srcData[row.id][field]);
              console.log(action.multiplier);
              if (!Number.isNaN(srcValue)) {
                row[field] = Math.trunc(
                  srcValue * action.multiplier
                ).toString();
              }
            });
          }
        });
        dispatch({
          type: "TABLE_DATA",
          payload: { [action.targetTableKey]: targetTable },
        });
        break;
      }

      case "UPDATE_WARNING_ICON": {
        const targetTableInfo = [...tableInfo[action.parent_table_key]];
        let targetRow = {};
        for (let i = 0; i < targetTableInfo.length; i++) {
          if (targetTableInfo[i].id === dynamicPayload[0]) {
            targetRow = { ...targetTableInfo[i] };
          }
        }
        let childRowIds = targetRow?.child_row_id;
        let childFinalisedDateMatchFlag = true;
        let childrenRows = [];
        targetTableInfo.map((row) => {
          if (childRowIds.includes(row.id)) {
            childrenRows.push(row);
          }
        });

        for (let i = 0; i < childrenRows.length; i++) {
          if (
            childrenRows[i].finalised_clearence_date !== selectedValue &&
            childrenRows[i].id !== targetRow.id
          ) {
            childFinalisedDateMatchFlag = false;
          }
        }

        if (childFinalisedDateMatchFlag) {
          targetTableInfo.map((row, index) => {
            if (childRowIds.includes(row["id"])) {
              row["#add_icon_parent_style_id"] = "";
              row["clearence_indicator"] = "LOW,green";
              targetTableInfo[index] = row;
            }
          });
        }
        if (!childFinalisedDateMatchFlag) {
          targetTableInfo.map((row, index) => {
            if (childRowIds.includes(row["id"])) {
              row["#add_icon_parent_style_id"] = "Y";
              row["clearence_indicator"] = "URGENT,red";
              targetTableInfo[index] = row;
            }
          });
        }

        dispatch({
          type: "TABLE_DATA",
          payload: { [action.parent_table_key]: targetTableInfo },
        });
        break;
      }
      case "CLEAR_BUFFER": {
        const obj = {};
        action.keys.forEach((key) => (obj[key] = []));
        dispatch({
          type: "ADD_TO_BUFFER",
          payload: obj,
        });
        break;
      }
      case "COPY_ROW": {
        const tableData = tableInfo[`${action.parent_table_key}`];
        if (tableData && tableData.length > 0) {
          const rows = selectedRowInfo[action.parent_table_key];
          if (rows && rows.length > 0) {
            if (action?.copyFromHidden) {
              let selectedRows = rows.map((row) => row["#id"]);
              if (selectedRows && selectedRows.length > 0) {
                selectedRows = new Set(selectedRows);
                const newTableData = [];
                let index = tableData.length;
                tableData.forEach((row) => {
                  if (selectedRows.has(row.id)) {
                    const rowCopy = { ...row };
                    rowCopy.id = ++index;
                    if (action?.updateFields) {
                      Object.keys(action.updateFields).forEach(
                        (key) => (rowCopy[key] = action.updateFields[key])
                      );
                    }
                    newTableData.push(rowCopy);
                    //pushing duplicated row with new id & editable flag
                  }
                  newTableData.push(row);
                });
                console.log(newTableData);
                const params = {};
                params[`${action.parent_table_key}`] = newTableData;
                dispatch({
                  type: "TABLE_DATA",
                  payload: params,
                });
              }
            } else {
              let selectedRows = rows.map((row) => row["id"]);
              if (selectedRows && selectedRows.length > 0) {
                selectedRows = new Set(selectedRows);
                const newTableData = [];
                let index = 0;
                tableData.forEach((row) => {
                  const rowCopy = { ...row };
                  row.id = index++;
                  newTableData.push(row);
                  if (selectedRows.has(rowCopy.id)) {
                    rowCopy.id = index++;
                    if (action?.updateFields) {
                      Object.keys(action.updateFields).forEach(
                        (key) => (rowCopy[key] = action.updateFields[key])
                      );
                    }
                    newTableData.push(rowCopy); //pushing duplicated row with new id & editable flag
                  }
                });
                const params = {};
                params[`${action.parent_table_key}`] = newTableData;
                dispatch({
                  type: "TABLE_DATA",
                  payload: params,
                });
              }
            }
          }
        }
        break;
      }
      case "ADD_ROW_TO_GROUP": {
        const tableData = tableInfo[`${action.parent_table_key}`];
        if (tableData && tableData.length > 0) {
          let rows = [];
          if (dynamicPayload) {
            if (action.payloadType === "row") {
              rows = [dynamicPayload];
            }
          } else {
            const gridApi = gridRefs[action.parent_table_key].current.api;
            gridApi.forEachNode((node) => {
              if (node.group && node.selected) {
                const lastChildIndex = node.allLeafChildren.length - 1;
                const lastChildRow = node.allLeafChildren[lastChildIndex].data;
                rows.push(lastChildRow);
              }
            });
          }
          if (rows && rows.length > 0) {
            let selectedRows = rows.map((row) => row["id"]);
            if (selectedRows && selectedRows.length > 0) {
              selectedRows = new Set(selectedRows);
              const newTableData = [];
              let index = 0;
              tableData.forEach((row) => {
                const rowCopy = { ...row };
                row.id = index++;
                newTableData.push(row);
                if (selectedRows.has(rowCopy.id)) {
                  rowCopy.id = index++;
                  if (action?.updateFields) {
                    Object.keys(action.updateFields).forEach(
                      (key) => (rowCopy[key] = action.updateFields[key])
                    );
                  }
                  newTableData.push(rowCopy); //pushing duplicated row with new id & editable flag
                }
              });
              const params = {};
              params[`${action.parent_table_key}`] = newTableData;
              dispatch({
                type: "TABLE_DATA",
                payload: params,
              });
            }
          }
        }
        break;
      }
      case "DISPLAY_BASED_ON_SELECTIONS": {
        const { params, optionsList } = dynamicPayload;
        const exceptions = new Set(action?.exceptions);
        const selectedItems = !Array.isArray(params.selectedItems)
          ? [params.selectedItems]
          : params.selectedItems;
        let selectedValues = selectedItems.map((filterObj) => filterObj.id);
        let dropdownParams = {};
        if (optionsList.length > 0) {
          optionsList.forEach((obj) => {
            dropdownParams[
              `${action.componentKey}_${obj.id}`
            ] = selectedValues.includes(obj.id);
          });
        }
        dispatch({
          type: "DEPENDENT_COMPONENTS",
          payload: dropdownParams,
        });
        break;
      }
      case "SET_COLUMNS_VISIBLE": {
        action.tables.forEach((key) => {
          const gridRef = gridRefs[key]?.current;
          if (gridRef) {
            if (action?.columnsToHide) {
              gridRef.columnApi.setColumnsVisible(action.columnsToHide, false);
            }
            if (action?.columnsToShow) {
              gridRef.columnApi.setColumnsVisible(action.columnsToShow, true);
            }
          }
        });
        break;
      }
      case "ENABLE_DISABLE_BUTTON_KEYS":
        {
          let params = {};
          params[action.key] = true;
          params[action.key1] = action.key1 ? true : null;
          params[action.key2] = action.key2 ? true : null;
          params[action.key3] = action.key3 ? true : null;
          params[action.key4] = action.key4 ? true : null;
          params[action.key5] = action.key5 ? true : null;
          params[action.key6] = action.key6 ? true : null;
          params[action.otherKey1] = action.otherKey1 ? false : null;
          params[action.otherKey2] = action.otherKey2 ? false : null;
          params[action.otherKey3] = action.otherKey3 ? false : null;
          params[action.otherKey4] = action.otherKey4 ? false : null;
          params[action.otherKey5] = action.otherKey5 ? false : null;
          params[action.otherKey6] = action.otherKey6 ? false : null;
          params[action.otherKey7] = action.otherKey7 ? false : null;
          params[action.otherKey8] = action.otherKey8 ? false : null;
          params[action.otherKey9] = action.otherKey9 ? false : null;
          params[action.otherKey10] = action.otherKey10 ? false : null;
          dispatch({
            type: "DEPENDENT_COMPONENTS",
            payload: params,
          });
        }
        break;
      case "DISTRIBUTE_DELTA_TO_ROWS": {
        let rows = tableInfo[action.targetTableKey];
        if (rows) {
          rows = [...rows];
          const delta = dynamicPayload / action.rows.length;
          rows.forEach((row) => {
            if (action.rows.includes(row.id)) {
              action.columns.forEach((field) => {
                const currentValue = +row[field];
                row[field] = (currentValue + delta).toString();
              });
            }
          });
          dispatch({
            type: "TABLE_DATA",
            payload: { [action.targetTableKey]: rows },
          });
        }
        break;
      }
      case "DISTRIBUTE_BY_PERC":
        {
          let rows = tableInfo[action.targetTableKey];
          if (rows) {
            rows = [...rows];
            rows.forEach((row) => {
              const perc = +row[action.sourceColumn] / 100;
              const newValue = dynamicPayload * perc;
              row[action.column] = Math.round(newValue).toString();
            });
            dispatch({
              type: "TABLE_DATA",
              payload: { [action.targetTableKey]: rows },
            });
          }
        }
        break;
      case "APPEND_COLUMN_DEFS":
        {
          const key = action.tableKey;
          const { overrwrite = true } = action;
          const params = {};
          if (Array.isArray(key)) {
            key.forEach((k) => {
              if (overrwrite) {
                params[k] = action.defs;
              } else {
                const storeDefs = tableDefs[k] || {};
                params[k] = { ...storeDefs, ...action.defs };
              }
            });
          } else {
            if (overrwrite) {
              params[key] = action.defs;
            } else {
              const storeDefs = tableDefs[key] || {};
              params[key] = { ...storeDefs, ...action.defs };
            }
          }
          {
            dispatch({
              type: "ADD_TABLE_DEFS",
              payload: params,
            });
          }
        }
        break;
      case "REDIRECT_EXTERNAL_LINK":
        {
          window.open(action.url);
        }
        break;
      case "TAB_REDIRECT":
        {
          if (action.path) {
            navigate(
              "/" +
                window?.location?.pathname?.split("/")[1] +
                "/" +
                window?.location?.pathname?.split("/")[2] +
                action.path
            );
          }
          dispatch({
            type: "TAB_REDIRECT",
            payload: {
              activeTabIndex: action.activeTabIndex,
              parentTabIndex: action.parentTabIndex,
              activeTabValue: action.activeTabValue,
              parentTabValue: action.parentTabValue,
            },
          });
        }
        break;
      case "CALCULATE":
        {
          const params = dynamicPayload;
          if (params) {
            let nodes = [];
            if (action.applyToAllNodes) {
              params.api.forEachNode((node) => {
                if (!node.group) {
                  nodes.push(node);
                }
              });
            } else {
              nodes = [params.node];
            }
            const funcs = {
              "+": (a, b) => a + b,
              "-": (a, b) => a - b,
              "*": (a, b) => a * b,
              "/": (a, b) => a / b,
              "%": (a, b) => a * (b / 100.0),
              "<<": (a, b) => b || a,
            };
            const parseArg = (arg, node) => {
              if (typeof arg === "number") {
                return arg;
              }
              let v = 0;
              if (arg.charAt(0) === "#") {
                //pick agg data
                v = node?.parent?.aggData?.[arg.slice(1)] || 0;
              } else {
                v = node?.data?.[arg] || 0;
              }
              if (typeof v === "number") return v;
              v = v.replace(/,|%/g, "");
              return Number(v);
            };
            const seq = action.expression;

            nodes.forEach((node) => {
              let result = parseArg(seq[0], node);
              for (let i = 2, j = 1; i < seq.length; i += 2, j += 2) {
                result = funcs[seq[j]](result, parseArg(seq[i], node));
              }
              const v = !isFinite(result)
                ? "0"
                : formatNumber(result, action.valueType || "float");
              if (action.updateByRefresh) {
                node.data[action.targetColumn] = v;
                params.api.refreshCells({ rowNodes: nodes });
              } else {
                node.setDataValue(action.targetColumn, v);
              }
            });
            setTimeout(() => {
              params.api.refreshClientSideRowModel("aggregate");
            }, 0);
          }
        }
        break;
      case "REVERT_CELL_VALUE":
        {
          const params = paramsRefs[action.parentTableKey];
          if (params) {
            params.node.data[params.column.getColId()] = params.oldValue;
            setTimeout(() => {
              params.api.refreshCells({
                rowNodes: [params.node],
                force: true,
              });
              params.api.refreshClientSideRowModel("aggregate");
            }, 0);
            // params.node.setDataValue(params.column.getColId(), params.oldValue);
          }
        }
        break;
      case "APPEND_NEW_DATA_TO_TABLE": {
        const sourceData = tableInfo[`${action.source_table_key}`];
        const parentData = tableInfo[`${action.parent_table_key}`];
        let payload = {};
        if (parentData) {
          payload[`${action.parent_table_key}_initial`] = [...parentData];
        }
        if (parentData && sourceData) {
          payload[`${action.parent_table_key}`] = [
            ...parentData,
            ...sourceData,
          ];
        }
        dispatch({
          type: "TABLE_DATA",
          payload: payload,
        });
        break;
      }
      case "REVERT_TABLE_DATA": {
        const tabledata =
          tableInfo[
            `${action?.parent_table_key}_initial` ||
              `${action.parent_table_key}_copy` ||
              `${action.source_table_key}`
          ];
        let payload = {};
        if (tabledata) {
          payload[`${action.parent_table_key}`] = [...tabledata];
        }
        dispatch({
          type: "TABLE_DATA",
          payload: payload,
        });
        break;
      }
      case "CELL_COMPUTE": {
        const sourceRows = tableInfo[action.sourceTableKey];
        const destRows = [...tableInfo[action.destTableKey]];
        if (sourceRows && destRows) {
          const targetRow = destRows[0];
          if (action.hasOwnProperty("targetRowId")) {
            targetRow = destRows.find((row) => row.id == action.targetRowId);
          }
          if (targetRow) {
            Object.keys(action.columns).forEach((sourceField) => {
              const destField = action.columns[sourceField];
              let sum = 0;
              sourceRows.forEach((row) => {
                const v =
                  typeof row[sourceField] == "number"
                    ? row[sourceField]
                    : Number(row[sourceField].replace(/,|%|\$|€/g, ""));
                sum += v;
              });
              targetRow[destField] = sum;
            });

            const { allocated_qty, allocated_qty_default } = destRows;
            let diff = allocated_qty_default - allocated_qty;
            let factor = diff / 6;

            targetRow.qty_6B = targetRow.qty_6B + factor;
            targetRow.qty_7B = targetRow.qty_7B + factor;
            targetRow.qty_8B = targetRow.qty_8B + factor;
            targetRow.qty_9B = targetRow.qty_9B + factor;
            targetRow.qty_10B = targetRow.qty_10B + factor;
            targetRow.qty_11B = targetRow.qty_11B + factor;
            dispatch({
              type: "TABLE_DATA",
              payload: { [action.destTableKey]: destRows },
            });
          }
        }
        break;
      }
      case "SET_ROWS_DATA_BY_COLUMN_VALUE":
        {
          function setNodeValue(node, action) {
            const data = node.group ? node.allLeafChildren[0].data : node.data;
            if (data) {
              let { compareValue, outputValues } = action.condition;
              const cellValue = cellValueToNumber(data[action.sourceColumn]);
              if (typeof compareValue === "string") {
                compareValue = cellValueToNumber(data[compareValue]);
              }
              const value =
                cellValue < compareValue
                  ? outputValues[0]
                  : cellValue > compareValue
                  ? outputValues[2]
                  : outputValues[1];
              action.targetColumns.forEach((field) => {
                data[field] = value;
              });
            }
          }
          if (dynamicPayload) {
            if (action.payloadType === "row") {
              setNodeValue(dynamicPayload, action);
              const gridApi = gridRefs[action.tableKey]?.current?.api;
              if (gridApi) {
                setTimeout(() => {
                  gridApi.refreshCells(
                    {
                      rowNodes: [dynamicPayload],
                      force: true,
                      suppressFlash: true,
                    },
                    0
                  );
                });
              }
            }
          } else {
            const gridApi = gridRefs[action.tableKey]?.current?.api;
            if (gridApi) {
              gridApi.forEachNode((node) => {
                setNodeValue(node, action);
              });
              setTimeout(() => {
                gridApi.refreshCells({
                  force: true,
                  suppressFlash: true,
                });
              }, 0);
            }
          }
        }
        break;
      case "SHOW_HIDE_TABLE_COLUMNS": {
        //getting tableKey from json and table api reference for that table
        const TABLE_API = gridRefs?.[action.tableKey]?.current?.columnApi;
        const allColumns = TABLE_API?.columnModel.getAllGridColumns();
        const filteredColumns = [];

        //for all keys that we are getting from json as an array filtering the columns
        action?.keys?.forEach((key) => {
          allColumns?.forEach((col) => {
            const column = col?.getColId();

            //if show/hide required at parent level then this will check for a particular keyword
            //that is present in all the column fields present in sheet
            //otherwise will check for the same field
            if (
              (action?.atParentLevel && column.includes(key)) ||
              column === key
            )
              filteredColumns.push({ id: column, visible: col.isVisible() });
          });
        });

        //making filtered columns visible or hidden based on thier previous state
        filteredColumns.forEach((column) => {
          TABLE_API.setColumnVisible(column.id, !column.visible);
        });

        break;
      }
      case "FILTER_DATA": {
        let filterParams = {};
        let tableData = [];
        action?.copyIsSource
          ? (tableData = tableInfo[`${action.source}_copy`])
          : (tableData = tableInfo[action.source]);
        const updatedData = tableData?.filter((obj) => {
          return !action?.mappingKey?.some((key, index) => {
            // Check if the object's value for the current key is in the corresponding data_key array
            let value = action?.data_key[index]?.includes(obj[key]);
            return action?.isReverseRequired ? !value : value;
          });
        });
        filterParams[action.source] = updatedData;

        dispatch({
          type: "TABLE_DATA",
          payload: filterParams,
        });

        break;
      }
      case "UPDATE_GRAPH_INFO": {
        const { params } = dynamicPayload;
        const selectedItem = Array.isArray(params.selectedItems)
          ? params.selectedItems[0]
          : params.selectedItems;
        const itemValue = selectedItem?.itemValue
          ? `_${selectedItem.itemValue}`
          : "";
        const info = {
          [action.graphKey]: {
            dataKey: `${action.apiHeader}${itemValue}`,
          },
        };
        dispatch({
          type: "COMPONENT_INFO",
          payload: info,
        });
        break;
      }
      case "UPDATE_DATA_BASED_ON_SELECTION":
        const { params } = dynamicPayload;
        const selectedValue = params?.selectedItems?.[0]?.value;
        const tableMapping = action?.source_table_mapping;
        if (tableMapping?.hasOwnProperty(selectedValue)) {
          const sourceData = tableInfo[`${tableMapping[selectedValue]}`];
          if (sourceData !== undefined) {
            dispatch({
              type: "TABLE_DATA",
              payload: {
                [action?.parent_table_key]: sourceData,
              },
            });
          }
        } else {
          console.error("Table mapping for selected option does not exist");
        }
        break;
      case "SAVE_GRID_DATA":
        {
          const gridApi = gridRefs[action.parentTableKey]?.current?.api;
          if (gridApi) {
            const rowMap = {};
            gridApi.forEachLeafNode((node) => {
              const filteredData = {};
              action.columns.forEach(
                (field) => (filteredData[field] = node.data[field])
              );
              rowMap[node.data["id"]] = filteredData;
            });
            const tableData = tableInfo[action.parentTableKey];
            const updatedData = tableData.map((row) => {
              const mapData = rowMap[row["id"]];
              return mapData
                ? {
                    ...row,
                    ...rowMap[row["id"]],
                  }
                : row;
            });
            dispatch({
              type: "TABLE_DATA",
              payload: {
                [action.parentTableKey]: updatedData,
              },
            });
          }
        }
        break;
      case "INSERT_SAVEDATA_TO_GRID":
        {
          const gridApi = gridRefs[action.parentTableKey]?.current?.api;
          if (gridApi) {
            const tableData = tableInfo[action.parentTableKey];
            const rowMap = {};
            tableData.forEach((row) => {
              const filteredData = {};
              action.columns.forEach(
                (field) => (filteredData[field] = row[field])
              );
              rowMap[row["id"]] = filteredData;
            });
            const nodeToRefresh = [];
            gridApi.forEachLeafNode((node) => {
              const mapData = rowMap[node.data["id"]];
              if (mapData) {
                node.data = { ...node.data, ...mapData };
                nodeToRefresh.push(node);
              }
            });
            gridApi.refreshCells({ rowNodes: nodeToRefresh });
            gridApi.refreshClientSideRowModel("aggregate");
          }
        }
        break;
      case "DELAYED_SET_KEYS":
        const delay = action.delay || 1000;
        setTimeout(() => {
          dispatch({
            type: "DEPENDENT_COMPONENTS",
            payload: action.data,
          });
        }, delay);
        break;
      case "UPDATE_DEPENDENT_BY_SELECTION_COUNT":
        {
          const { params, optionsList } = dynamicPayload;
          const selectionCount = params?.selectedItems?.length;
          const info = {};
          info[`${action.dependentKey}_some`] = selectionCount > 0;
          info[`${action.dependentKey}_single`] = selectionCount === 1;
          info[`${action.dependentKey}_every`] =
            selectionCount === optionsList.length;
          dispatch({
            type: "DEPENDENT_COMPONENTS",
            payload: info,
          });
        }
        break;
      case "SPLIT_BY_PERC":
        {
          const { newValue, node, colDef, api } = dynamicPayload;
          const v = cellValueToNumber(newValue);
          const proportions = node.data[`#splitProportion_${colDef.field}`]
            ?.split(",")
            ?.map((v) => Number(v));
          if (proportions) {
            action.columns.forEach((field, idx) => {
              const perc = proportions[idx] / 100;
              const colNewValue = formatNumber(v * perc);
              node.data[field] = colNewValue;
            });
            api.refreshCells({ rowNodes: [node] });
          }
        }
        break;
      case "SPLIT_SAVE":
        {
          const sourceGripApi = gridRefs[action.source.tableKey].current.api;
          if (sourceGripApi) {
            const sourceNodes = [];
            sourceGripApi.forEachLeafNode((node) =>
              sourceNodes.push(node.data)
            );
            const valueToSplit = cellValueToNumber(
              sourceNodes[0][action.source.column]
            );
            const targetTable = tableInfo[action.target.tableKey];
            if (targetTable) {
              const rows = [...targetTable];
              rows.forEach((row) => {
                let perc = cellValueToNumber(
                  row[`#splitSaveProportions_${action.target.column}`]
                );
                perc = perc / 100;
                const value = valueToSplit * perc;
                if (action.target.recursive) {
                  const h_proportions = row[
                    `#splitProportion_${action.target.column}`
                  ]
                    ?.split(",")
                    ?.map((v) => Number(v));
                  if (h_proportions) {
                    action.target.recursive.columns.forEach((field, idx) => {
                      const perc = h_proportions[idx] / 100;
                      const colNewValue = formatNumber(value * perc);
                      row[field] = colNewValue;
                    });
                  }
                }
                row[action.target.column] = formatNumber(value);
              });
              dispatch({
                type: "TABLE_DATA",
                payload: { [action.target.tableKey]: rows },
              });
            }
          }
        }
        break;
      case "navigate":
        {
          const paths = window?.location?.pathname?.split("/");
          navigate("/" + paths[1] + "/" + paths[2] + action.path);
        }
        break;
      case "displayMessage":
        dispatch({
          type: "DISPLAY_MESSAGE",
          payload: {
            showMessage: true,
            message: action.message ?? "Updated Sucessfully",
            variant: action.variant,
            position: action.position ?? "bottom-left",
          },
        });
        if (action.path) {
          setTimeout(() => {
            navigate(
              "/" +
                window?.location?.pathname?.split("/")[1] +
                "/" +
                window?.location?.pathname?.split("/")[2] +
                action.path
            );
            if (action.redirect) {
              dispatch({
                type: "TAB_REDIRECT",
                payload: {
                  activeTabIndex: action.activeTabIndex,
                  parentTabIndex: action.parentTabIndex,
                  activeTabValue: action.activeTabValue,
                  parentTabValue: action.parentTabValue,
                },
              });
            }
          }, 2500);
        }
        break;
      case "displayAlert":
        dispatch({
          type: "DISPLAY_ALERT",
          payload: {
            showAlert: true,
            description: action.description ?? "Updated Sucessfully",
            variant: action.variant,
            position: action.position ?? "bottom-left",
            actionButtonName: action.actionButtonName ?? "Undo",
            onAction: action.onAction ?? null,
            onClose: action.onClose ?? null,
            title: action.title ?? "title",
          },
        });
        break;
      case "UPDATE_API_KEYS_BY_SELECTION":
        {
          const { params } = dynamicPayload;
          const selectedItem = Array.isArray(params.selectedItems)
            ? params.selectedItems[0]
            : params.selectedItems;
          const apiMapping = selectedItem?.componentApiMapping;
          if (apiMapping) {
            const _map = apiMapping.split("|").map((item) => {
              const [componentKey, dataKey] = item.split(",");
              return { componentKey, dataKey };
            });
            const payload = {};
            _map.forEach(({ componentKey, dataKey }) => {
              //only if component has initialized, update
              if (componentInfo[componentKey]) {
                payload[componentKey] = {
                  ...componentInfo[componentKey],
                  dataKey,
                };
              }
            });
            dispatch({
              type: "COMPONENT_INFO",
              payload: payload,
            });
          }
        }
        break;
      case "DISPLAY_TAB":
        {
          let payload = action?.tabValues;
          dispatch({
            type: "SET_TAB_DEPENDENCY",
            payload: payload,
          });
          if (action?.activeTabs) {
            dispatch({
              type: "SET_ACTIVE_TAB",
              payload: action.activeTabs,
            });
          }
        }
        break;
      case "ACTIVE_TAB":
        {
          dispatch({
            type: "SET_ACTIVE_TAB",
            payload: action.data,
          });
        }
        break;
      case "APPEND_DROPDOWN_ROWS": {
        const columns = Object.keys(tableInfo?.[action?.dest_table_key]?.[0]);
        if (action?.multipleBasedOn) {
          const limit = buffer?.[action?.multipleBasedOn]?.length;
          let rows = [...tableInfo?.[action?.dest_table_key]];
          for (var i = 0; i < limit; i++) {
            let rowObj = {};
            columns.map((item) => {
              if (buffer?.hasOwnProperty(item)) {
                if (isArray(buffer?.[item])) {
                  rowObj = {
                    ...rowObj,
                    [item]:
                      buffer?.[item]?.map((val) => val.label)?.[i] ||
                      buffer?.[item]?.map((val) => val.label)?.[0],
                  };
                } else {
                  rowObj = {
                    ...rowObj,
                    [item]: buffer?.[item],
                  };
                }
              } else {
                rowObj = {
                  ...rowObj,
                  [item]:
                    tableInfo?.[action?.dest_table_key]?.[0]?.[item] || "",
                };
              }
            });
            if (rowObj?.hasOwnProperty("#hidden")) {
              delete rowObj?.["#hidden"];
            }
            rows.push(rowObj);
          }
          dispatch({
            type: "TABLE_DATA",
            payload: {
              [action?.dest_table_key]: rows,
            },
          });
        }
      }
      break;
      case "UPDATE_BUFFER":
        {
          let bufferClone = cloneDeep(buffer);
          if (action?.add) {
            bufferClone = {
              ...bufferClone,
              ...action?.add,
            };
          }
          if (action?.remove) {
            action?.remove?.map((item) => delete bufferClone?.[item]);
          }
          dispatch({
            type: "ADD_TO_BUFFER",
            payload: bufferClone,
          });
        }
        break;
      case "SET_ACTIVE_FILTER_PANEL_TAB":
          dispatch({
            type: "SET_ACTIVE_FILTER_PANEL_TAB",
            payload: action?.tabName?.replace(" ", "-")?.toLowerCase(),
          })
        break;
      case "CHANGE_TABLE_VIEW": {
        //NOTE: You need to pass "dropdown_id" same as action's dropdown IDs and actionType as "OPTION_SELECT" to dropdown
        //For changing columns view you need to pass storeAllOptions: true to dropdown
        action?.columnDropdownIds?.forEach((id) => {
          const selectedOptions = dropdownSelectionData[
            id
          ]?.selectedOptions?.map((opt) => opt.value);
          const allOptions = dropdownSelectionData[id]?.allOptions?.map(
            (opt) => opt.value
          );
          const hiddenColumns = allOptions
            ?.filter((option) => selectedOptions?.indexOf(option) === -1)
            ?.reduce((result, value) => {
              if (action?.columnAction?.columnIdOptionMapper?.[value]) {
                result.push(
                  ...action?.columnAction?.columnIdOptionMapper?.[value]
                );
              }
              return result;
            }, []);
          const visibleColoums = selectedOptions?.reduce((result, value) => {
            if (action?.columnAction?.columnIdOptionMapper?.[value]) {
              result.push(
                ...action?.columnAction?.columnIdOptionMapper?.[value]
              );
            }
            return result;
          }, []);
          const TABLE_API =
            gridRefs?.[action?.columnAction?.tableKey]?.current?.columnApi;
          hiddenColumns?.forEach((column) => {
            TABLE_API?.setColumnVisible(column, false);
          });
          visibleColoums?.forEach((column) => {
            TABLE_API?.setColumnVisible(column, true);
          });
        });

        let mappingKey = [];
        const dataKey = action?.rowDropdownIds
          ?.filter((item) => dropdownSelectionData[item.label])
          .reduce((result, item) => {
            mappingKey.push(item.columnId);
            const options = dropdownSelectionData[item.label];
            if (options) {
              const data = Array.isArray(options)
                ? options.map((opt) => opt.value)
                : [options.value];
              result.push(data);
            } else {
              result.push([]);
            }

            return result;
          }, []);
        if (mappingKey.length > 0 && dataKey.length > 0) {
          onAction({
            ...action.rowAction,
            actionName: "FILTER_DATA",
            mappingKey: action.rowAction?.mappingKey || mappingKey,
            data_key: action.rowAction?.dataKey || dataKey,
          });
        }
        break;
      }
      default:
        actionOccurred = false;
    }
    return actionOccurred;
  }
  function invokeAction(action, dynamicPayload = null, selectedValue) {
    if (action && Array.isArray(action)) {
      action.forEach((obj) => onAction(obj, dynamicPayload));
    } else {
      onAction(action, dynamicPayload, selectedValue);
    }
  }
  return invokeAction;
}

function cellValueToNumber(value) {
  if (typeof value === "number") {
    return value;
  }
  if (isNaN(value)) {
    return parseInt(value.replace(/,|%/g, ""));
  }
  return Number(value);
}
