import PostAddIcon from "@mui/icons-material/PostAdd";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import BarChartIcon from "@mui/icons-material/BarChart";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import OpenInFull from "@mui/icons-material/OpenInFull";
import { Tooltip } from "impact-ui";
import {
  InfoOutlined,
  SpeedOutlined,
  TrendingUp,
  VisibilityOutlined,
} from "@mui/icons-material";
import DownloadIcon from "@mui/icons-material/Download";
import ThreeDotsIcon from "@mui/icons-material/MoreHoriz";
import CollapseIcon from "@mui/icons-material/CloseFullscreen";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import PieChart from "../../../../../assets/svg/pieChart.svg";

export const getIAIcon = (item, index) => {
  const { parent_table_key, iconType, style = {} } = item;
  switch (iconType) {
    case "undo":
      return <span class="material-symbols-outlined">undo</span>;
    case "refresh":
      return <span class="material-symbols-outlined">refresh</span>;
    case "setting":
      return <span class="material-symbols-outlined">settings</span>;
    case "match":
      return <span class="material-symbols-outlined">rebase</span>;
    case "calculate":
      return <span class="material-symbols-outlined">calculate</span>;
    case "download":
      return <span class="material-symbols-outlined">download_2</span>;
    case "edit":
      return <span class="material-symbols-outlined">edit</span>;
    case "search":
      return <span class="material-symbols-outlined">search</span>;
    case "delete":
      return <span class="material-symbols-outlined">delete</span>;
    case "copy":
      return <span class="material-symbols-outlined">content_copy</span>;
    case "graph":
      return (
        <svg
          width="18"
          height="19"
          viewBox="0 0 18 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.698 12.5359H2.988C2.808 12.5359 2.664 12.673 2.664 12.8445V16.0857C2.664 16.2572 2.808 16.3944 2.988 16.3944H4.698C4.878 16.3944 5.022 16.2572 5.022 16.0857V12.8445C5.022 12.673 4.878 12.5359 4.698 12.5359ZM7.974 10.5809H6.264C6.084 10.5809 5.94 10.7181 5.94 10.8896V16.0857C5.94 16.2572 6.084 16.3944 6.264 16.3944H7.974C8.154 16.3944 8.298 16.2572 8.298 16.0857V10.8896C8.298 10.7181 8.154 10.5809 7.974 10.5809ZM11.304 8.40297H9.594C9.414 8.40297 9.27 8.54016 9.27 8.71165V16.0857C9.27 16.2572 9.414 16.3944 9.594 16.3944H11.304C11.484 16.3944 11.628 16.2572 11.628 16.0857V8.71165C11.628 8.54016 11.484 8.40297 11.304 8.40297ZM14.724 5.69344H13.014C12.834 5.69344 12.69 5.83063 12.69 6.00212V16.0857C12.69 16.2572 12.834 16.3944 13.014 16.3944H14.724C14.904 16.3944 15.048 16.2572 15.048 16.0857V6.00212C15.066 5.83063 14.904 5.69344 14.724 5.69344ZM11.412 5.07608L14.346 1.95498C14.634 1.6463 14.598 1.18328 14.292 0.926044C13.968 0.651662 13.482 0.685959 13.212 0.977491L10.512 3.84136L8.316 3.78991C8.082 3.78991 7.884 3.87566 7.722 4.03L4.284 7.63127L3.546 6.99676L2.79 10.2893L6.156 9.26041L5.418 8.6259L8.622 5.26472L10.818 5.31616C11.052 5.33331 11.268 5.24757 11.412 5.07608Z"
            fill="#394960"
          />
        </svg>
      );
    case "table":
      return <TableChartOutlinedIcon />;
    case "PostAdd":
      return <PostAddIcon />;
    case "visible":
      return <span class="material-symbols-outlined"> visibility </span>;
    case "copy":
      return <span class="material-symbols-outlined">content_copy </span>;
    case "compare":
      return <span class="material-symbols-outlined"> compare</span>;
    case "add":
      return <span class="material-symbols-outlined">add</span>;
    case "expandCircleDownIcon":
      return <span class="material-symbols-outlined">expand_circle_down</span>;
    case "time":
      return <span class="material-symbols-outlined">schedule </span>;
    case "filter":
      return <span class="material-symbols-outlined">filter_alt</span>;
    case "queryStats":
      return <span class="material-symbols-outlined">query_stats</span>;
    case "upload":
      return <span class="material-symbols-outlined"> upload_2</span>;
    case "reset":
      return <span class="material-symbols-outlined"> restart_alt</span>;
    case "menuBook":
      return <span class="material-symbols-outlined"> menu_book </span>;
    case "history":
      return <span class="material-symbols-outlined"> history</span>;
    case "lock":
      return <span class="material-symbols-outlined">lock </span>;
    case "unlock":
      return <span class="material-symbols-outlined"> lock_open </span>;
    case "barChart":
      return <BarChartIcon />;
    case "remove":
      return <CloseIcon />;
    case "backArrow":
      return <ArrowBackOutlinedIcon />;
    case "arrowForward":
      return <ArrowForwardOutlinedIcon />;
    case "forwardArrow":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill="#e8eaed"
        >
          <path d="M647-440H160v-80h487L423-744l57-56 320 320-320 320-57-56 224-224Z" />
        </svg>
      );
    case "scale":
      return <OpenInFull />;
    case "info": {
      return (
        <Tooltip
          text={item.tooltipText}
          disabled={!Boolean(item.tooltipText) || item.disabledTooltip}
          placement={item.tooltipPlacement || "bottom"}
          isPortal={item.isPortal || false}
          zIndex={item.zIndex || 5}
        >
          <InfoOutlined sx={{ ...item?.infoStyle }} />
        </Tooltip>
      );
    }
    case "Download":
      return <DownloadIcon />;
    case "threeDots":
      return <ThreeDotsIcon />;
    case "collapse":
      return <CollapseIcon />;
    case "save":
      return <span class="material-symbols-outlined">save</span>;
    case "quantity":
      return <ProductionQuantityLimitsIcon />;
    case "sales":
      return <AttachMoneyIcon />;
    case "menu":
      return <MoreHorizIcon />;
    case "newFilters":
      return <span class="material-symbols-outlined">tune</span>;
    case "trendingUp":
      return <TrendingUp />;
    case "pieChart":
      return <img src={PieChart} />;
    case "speed":
      return <SpeedOutlined />;
    case "arrowDown":
      return <span class="material-symbols-outlined">keyboard_arrow_down</span>;
    case "arrowChevronForward":
      return <span class="material-symbols-outlined">chevron_forward</span>;
    case "view":
      return <VisibilityOutlined sx={style} />;
    default:
      return null;
  }
};
