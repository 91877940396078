import React, { useState } from "react";
import moment from "moment";

// custom components
import CustomDateRangePicker from "../../../componentsV2/CustomDateRangePicker";

const CustomDateRange = (props) => {
  const { data = {} } = props;
  const {
    defaultFromDate,
    defaultToDate,
    isMandatory,
    labelStyle,
    label,
    labeldirection,
    style,
    standalone = false
  } = data;

  const state = [
    new Date(moment(defaultFromDate)),
    new Date(moment(defaultToDate)),
  ];

  const [dates, setDates] = useState(state);

  const handleDateRangeChange = (values) => {
    // console.log(values)
    setDates(values);
  };

  return (
    <>
      <CustomDateRangePicker
        // label={data.label}
        label={
          isMandatory ? (
            <p style={labelStyle}>
              {label}
              <span style={{ color: "red" }}>*</span>
            </p>
          ) : (
            <p style={labelStyle}>{label}</p>
          )
        }
        labeldirection={labeldirection || "column"}
        values={dates}
        startDateValues={defaultFromDate}
        endDateValues={defaultToDate}
        setValues={setDates}
        isMandatory={false}
        width="4rem"
        onChange={(values) => handleDateRangeChange(values)}
        style={style}
        standalone={standalone}
      />
    </>
  );
};
export default CustomDateRange;
