import _ from "lodash";
import { useEffect, useState } from "react";
import { Checkbox } from "impact-ui";
import { useSelector, useDispatch } from "react-redux";
import { getMultipleModelsData } from "../../action";
import "./customFiltersStyle.css";
import useAction from "../../../../utils/useAction";
import { getIAIcon } from "../CustomActionButton/buttonIcons/getButtonIAIcon";
const sheetMap = {
  A: "tb_custom_filters_a",
};
export default function CustomFilters({ data }) {
  const { variant, filterId, info = {} } = data;
  const apiKey = sheetMap[variant];
  const [filterData, setFilterData] = useState();
  const { tableInfo } = useSelector((state) => state.home);
  const dispatch = useDispatch();
  useEffect(async () => {
    try {
      const tableData = tableInfo[apiKey] || {};
      let storedFilterData = tableData[filterId];
      if (!storedFilterData) {
        const res = await getMultipleModelsData({
          model_names: [apiKey],
        });
        storedFilterData = res[apiKey].filter(
          ({ parent_id }) => parent_id == filterId
        );
        dispatch({
          type: "TABLE_DATA",
          payload: {
            [apiKey]: { ...tableData, [filterId]: storedFilterData },
          },
        });
        setFilterData(_.cloneDeep(storedFilterData));
      }
      setFilterData(_.cloneDeep(storedFilterData));
    } catch (error) {
      console.log(error);
    }
  }, [apiKey, filterId]);

  function resolver(variant, filterData) {
    switch (variant) {
      case "A": {
        return <VariantA data={filterData} info={info} />;
      }
    }
  }
  return <>{filterData ? resolver(variant, filterData) : null}</>;
}
function VariantA({ data, info }) {
  const { iconActions, type = "single-select" } = info;
  const [items, setItems] = useState(() =>
    data.map((item) => ({ ...item, selected: item.selected === "Y" }))
  );
  const invokeAction = useAction();
  function handleClick(index) {
    setItems((prev) => {
      switch (type) {
        case "multi-select": {
          const arr = [...prev];
          arr[index] = {
            ...arr[index],
            selected: !arr[index].selected,
          };
          return arr;
        }
        default: {
          return prev.map((item, idx) => ({
            ...item,
            selected: item.selected ? false : idx === index,
          }));
        }
      }
    });
  }
  function handleIconAction(e, index) {
    if (iconActions) {
      invokeAction(iconActions[index]);
    }
    e.stopPropagation();
  }
  function RenderChip({ click, label, selected, icons }) {
    const btns = icons ? icons.split(",") : null;
    return (
      <div
        className={`variant-a-chip ${selected ? "selected" : ""}`}
        onClick={click}
      >
        {selected && <Checkbox checked={true} />}
        <p>{label}</p>
        {btns && (
          <div style={{ display: "flex", marginTop: "3px" }}>
            {btns.map((iconType, index) => (
              <div onClick={(e) => handleIconAction(e, index)}>
                {getIAIcon({ iconType })}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
  return (
    <div className="variant-a-container">
      {items.map((item, index) => {
        return <RenderChip click={() => handleClick(index)} {...item} />;
      })}
    </div>
  );
}
