import { useCallback, useEffect, useState } from "react";
import ComponentResolver from "../Resolver/ComponentResolver";
import { useSelector } from "react-redux";
import useAction from "../../../utils/useAction";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  containerStyle: (props) => {
    return {
      ...props.style,
      "&:hover": {
        ...props.hoverStyle,
      },
    };
  },
}));

const Container = (props) => {
  const { data } = props;
  const [childComponentsList, setChildComponentsList] = useState([]);
  const { filtersDependentInfo } = useSelector((state) => state.home);
  const invokeAction = useAction();
  const classes = useStyles({ style: data.style, hoverStyle: data.hoverStyle });

  useEffect(() => {
    setChildComponentsList(data?.components || []);
    invokeAction(data?.actionOnMount)
    return () => {
      invokeAction(data?.actionOnUnmount);
    };
  }, [data]);
  

  const renderContainer = useCallback(
    (comp) => {
      if (comp?.filtersDependentOn) {
        const [id, category, option] = data.filtersDependentOn;
        if (Object.keys(filtersDependentInfo).length > 0) {
          if (filtersDependentInfo[id].hasOwnProperty(category)) {
            console.log(filtersDependentInfo[id][category]);
            if (filtersDependentInfo[id][category].size === 0) {
              return option === null;
            } else {
              return filtersDependentInfo[id][category].has(option);
            }
          }
          return false;
        }
        return false;
      }
      return true;
    },
    [filtersDependentInfo]
  );

  return renderContainer(data, filtersDependentInfo) ? (
    <div
      className={classes.containerStyle}
      onClick={() => invokeAction(data?.action)}
      {...(data?.draggable && {
        draggable: true,
        onDragEnd: () => invokeAction(data?.onDragEndAction),
      })}
    >
      <ComponentResolver
        pageObject={{
          components: data.components,
          legacyTabs: data?.legacyTabs,
        }}
        spread={true}
      />
    </div>
  ) : null;
};

export default Container;
